import { useState } from "react"
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route, useParams, useNavigate } from "react-router-dom";

import ProjectCard from "./ProjectCard.js"

import './App.css';

import languages from "./languages.js"

const HOST = "https://api.panoramanur.com"

const projects = [
	{ link: "https://www.youtube.com/watch?v=Xyc4bMAzs64", title: "Panorama Nur", image: "https://i.ytimg.com/vi/Xyc4bMAzs64/hqdefault.jpg?sqp=-oaymwE2COADEI4CSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhRIFooZTAP&rs=AOn4CLAxfYYv6zoQsJIjfjIYHYnAQWp9tw" },
	{ link: "https://www.youtube.com/watch?v=Xyc4bMAzs64", title: "Panorama Nur", image: "https://i.ytimg.com/vi/Xyc4bMAzs64/hqdefault.jpg?sqp=-oaymwE2COADEI4CSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhRIFooZTAP&rs=AOn4CLAxfYYv6zoQsJIjfjIYHYnAQWp9tw" },
]

const poruke = {
	default: {
		error: "Došlo je do greške",
		success: "Uspješno poslato",
		required: "Sva polja su obavezna",
	},
	en: {
		error: "Something went wrong",
		success: "Successfully sent",
		required: "All fields are required",
	},
	de: {
		error: "Etwas ist schiefgelaufen",
		success: "Erfolgreich gesendet",
		required: "Alle Felder sind erforderlich",
	}
}

function Home() {
	const [payload, setPayload] = useState({})

	const params = useParams()
	const navigate = useNavigate()

	const lang = languages?.[params?.lang] || languages?.default

	const inputChange = (text, key) => {
		setPayload(prevState => ({
			...prevState,
			[key]: text
		}))
	}

	const onSubmit = async (e) => {
		e.preventDefault()

		const someEmpty = ["firstName", "lastName", "email", "title", "message"].some(key => !payload[key])

		if(someEmpty) {
			toast((poruke[params?.lang] || poruke.default).required)
			return 0
		}

		try {
			const response = await fetch(`${HOST}/send-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(payload)
			})

			const { message } = await response.json()

			toast(message === "Successfully sent" ? (poruke[params?.lang] || poruke["default"]).success : message)
		} catch(e) {
			toast((poruke[params?.lang] || poruke["default"]).error)
		}
	}

  return (
    <div className="App">
			<header>
				<div>
					<img src="/icons/bar.svg" alt="" className="bar" />
				</div>

				<nav>
					<a href="#projekti">{lang?.projects}</a>
					<a href="#o-nama">{lang?.aboutus}</a>
					<a href="#zasto-mi">{lang?.whyus}</a>
					<a href="#kontaktiraj-nas">{lang?.contactus}</a>
				</nav>

				<div className="social">
					<a href="https://facebook.com" target="_blank">
						<img src="/icons/facebook.svg" alt="" className="socialLogo" />
					</a>
					<a href="https://twitter.com" target="_blank">
						<img src="/icons/twitter.svg" alt="" className="socialLogo" />
					</a>
					<a href="https://instagram.com" target="_blank">
						<img src="/icons/instagram.svg" alt="" className="socialLogo" />
					</a>
					<a href="https://youtube.com" target="_blank">
						<img src="/icons/youtube.svg" alt="" className="socialLogo" />
					</a>
				</div>
			</header>

			<main>

				<div className="section-1 section">
					<div>
						<div className="text-line">
							<div className="line hideOnSmall"></div>
							<p>{lang?.sectionTextLine1}</p>
						</div>

						<div className="cta cta-updated">
							<a href="#kontaktiraj-nas" className="btn">{lang?.ctaContactUs}</a>
							<p>{lang?.section1Text4}</p>
							<p>{lang?.section1Text3}</p>
							<p>{lang?.section1Text2}</p>
							<p>{lang?.section1Text1}</p>
						</div>
					</div>

					<div>
						{/*						<img src="https://arsigakonics.org/gif-images/11-video-editing.gif" alt="" /> */}
							<img src="/images/ja.jpeg" alt="" className="section-img" />
					</div>
				</div>

				<div className="section-2 section" id="projekti">
					<div className="top">
						<div className="text-line">
							<div className="line bg-yellow" />
							<p className="yellow">{lang?.sectionTextLine2}</p>
						</div>
						<h1>{lang?.section2Title}</h1>
					</div>

					<div className="projectContainer">
							{projects.map((item, idx) => (
								<ProjectCard lang={"ME"} image={item.image} link={item.link} title={item.title} key={idx} />
							))}
					</div>

					<div className="section-2-cta">
						<a href="/projects" className="btn btn-black">{lang?.section2Cta}</a>
						<p>{lang?.section2CtaDesc}</p>
					</div>
				</div>

				<div className="section-3 section" id="o-nama">
					<h1>{lang?.section3Title}</h1>

					<div className="section-3-inner">
						<div className="o-nama">
							<div className="text-line">
								<div className="line bg-yellow-dark" />
								<p className="yellow-dark">{lang?.section3TextLine}</p>
							</div>

							<p>
								{lang?.section3Desc1}
								 <br /> <br /> 
									{lang?.section3Desc2}
								<br /> <br /> 
									{lang?.section3Desc3}
							</p>
						</div>
						<div className="o-nama-photo">
							<div className="o-nama-photo-cont">
								<img src="/images/dron.jpeg" alt="" className="prva-slika" />

								<div>
									<img src="/images/priroda.jpeg" alt="" className="druga-slika" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="section-4 section" id="zasto-mi">
					<div className="text-line">
						<div className="line bg-yellow" />
						<p className="yellow">{lang?.section4Title}</p>
					</div>
					
					<div className="why">
						<div>
							<img src="/icons/drone.svg" alt="" className="why-svg" />
							<p>{lang?.section4Drone}</p>
						</div>

						<div>
							<img src="/icons/film.svg" alt="" className="why-svg" />
							<p>{lang?.section4Film}</p>
						</div>
					</div>
				</div>

				<div className="section section-5" id="kontaktiraj-nas">
					<div className="text-line mb">
						<div className="line bg-yellow" />
						<p className="yellow">{lang?.section5Title}</p>
					</div>
						
					<form onSubmit={onSubmit}>
						<div>
							<input type="text" onChange={(e) => inputChange(e?.target?.value, "firstName")} placeholder={lang?.firstName} />
							<input type="text" onChange={e => inputChange(e?.target?.value, "lastName")} placeholder={lang?.lastName} />
						</div>
						<div>
							<input type="email" onChange={e => inputChange(e?.target?.value, "email")} placeholder={lang?.email} />
							<input type="text" onChange={e => inputChange(e?.target?.value, "title")} placeholder={lang?.title} />
						</div>
						<textarea type="text" rows="4" cols="50" onChange={e => inputChange(e?.target?.value, "message")} placeholder={lang?.message}></textarea>
						<button type="submit" className="btn">{lang?.sendMessage}</button>
					</form>
				</div>

			</main>

			<footer>
				<div className="languages">
					{[{ key: "de", value: "Deutschland" }, { key: "en", value: "English" }, { key: "me", value: "Crnogorski" }].map((item, idx) => (
						<div key={item.key} onClick={() => {
							navigate(`/${item.key}`)
						}}>{item.value}</div>
					))}
				</div>
				<div className="social">
					<a href="https://facebook.com" target="_blank">
						<img src="/icons/facebook.svg" alt="" className="socialLogo" />
					</a>
					<a href="https://twitter.com" target="_blank">
						<img src="/icons/twitter.svg" alt="" className="socialLogo" />
					</a>
					<a href="https://instagram.com" target="_blank">
						<img src="/icons/instagram.svg" alt="" className="socialLogo" />
					</a>
					<a href="https://youtube.com" target="_blank">
						<img src="/icons/youtube.svg" alt="" className="socialLogo" />
					</a>
				</div>
				<div className="hl" />
				<p>Copyright @2023</p>
			</footer>
			<Toaster />
    </div>
  );
}

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/:lang/*" element={<Home />} />
				<Route path="" element={<Home />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App;
