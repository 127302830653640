const languages = {
	default: {
		projects: "Projekti",
		aboutus: "O meni",
		whyus: "Zašto ja?",
		contactus: "Kontaktiraj me",
		sectionTextLine1: `"Vizuelna priča koja ispunjava Vaše ciljeve."`,
		ctaContactUs: "Kontaktiraj me",
		section1Text1: "U današnje vrijeme, kada je digitalni svijet u punom zamahu, video je postao najmoćniji alat za komunikaciju. Video produkcija 'Panorama Nur' će Vam omogućiti da na impresivan način ispričate svoju priču ",
		section1Text2: "Ako tražite inovativan i učinkovit način da predstavite svoje poslovanje, video produkcija 'Panorama Nur' je upravo ono što Vam treba. Uz najnoviju tehnologiju i kreativni pristup, stvaramo visokokvalitetne videozapise koji će privući pažnju publike i poboljšati Vaše poslovanje.",
		section1Text3: "U moru marketinških alata, video je postao nezaobilazan dio uspješne marketinške strategije. Video produkcija 'Panorama Nur' je pravi izbor za Vas ako želite dosegnuti svoju publiku na učinkovit i zanimljiv način.",
		section1Text4: "Vaša priča zaslužuje biti ispričana na najbolji mogući način, a ja sam tu da Vam pomognem.",

		sectionTextLine2: "Moj rad",
		section2Title: "Moji prethodni radovi",
		section2Cta: "Pogledaj sve projekte",
		section2CtaDesc: "Ako želite vidjeti kako ja to radim, Pogledajte sve projekte",
		section3Title: "Video produkcija je proces stvaranja audiovizualnih sadržaja, koji se koristi za različite svrhe. To uključuje planiranje, snimanje, montažu i postprodukciju.",
		section3TextLine: "O meni",
		section3Desc1: "Kombinacijom inovativnosti, tehnologije i strateškog razmišljanja, video produkcija 'Panorama Nur' je kreativna, efektivna i ciljana.",
		section3Desc2: "Razumijem Vaše potrebe i radim sa Vama kako bi napravili video sadržaj koji će Vaš brend izdvojiti od konkurencije. Sa mnom, dobijate partnera koji će biti uz Vas od početka do kraja projekta, kako bi se postigli Vaši ciljevi.",
		section3Desc3: "Kontaktirajte me danas i dozvolite da radimo zajedno na stvaranju video sadržaja koji će Vas izdvojiti.",
		section4Title: "Zašto Ja",
		section4Drone: "Video produkcija 'Panorama Nur' Vam pruža priliku da Vaš proizvod, usluga ili mjesto gdje radite izgleda neodoljivo privlačno. Uz pomoć dronova snimam sa izuzetnog vazdušnog ugla, kako bi dobili nezaboravan doživljaj. ",
		section4Film: "Kreiram edukativne programe, tutorijale, spotove ili kratkometražne filmove koji će Vaš brend izdvojiti od konkurencije. Takođe mogu kreirati video najavu za Vaš budući događaj ili transformisati Vaše privatne snimke u jedinstveno sjećanje.",
		section44k: "Ukratko, ja sam stručnjak koji će Vaš brend predstaviti na način na koji zaslužuje. Kontaktirajte me danas i dozvolite da radimo zajedno na stvaranju nezaboravnog video sadržaja.",
		section5Title: "Kontaktiraj me",
		firstName: "Vaše ime",
		lastName: "Vaše prezime",
		email: "Vaš email",
		title: "Naslov",
		message: "Vaša poruka",
		sendMessage: "Pošalji poruku",
	},
	en: {
		projects: "Projects",
		aboutus: "About me",
		whyus: "Why me?",
		contactus: "Contact me",
		sectionTextLine1: "A visual story that achieves your goals.",
		ctaContactUs: "Contact me",
		section1Text1: "In today's digital world, when the digital landscape is in full swing, video has become the most powerful tool for communication. 'Panorama Nur' video production will enable you to tell your story in an impressive way.",
		section1Text2: "If you are looking for an innovative and effective way to present your business, 'Panorama Nur' video production is exactly what you need. With the latest technology and a creative approach, we create high-quality videos that will attract the attention of your audience and improve your business.",
		section1Text3: "In the sea of marketing tools, video has become an essential part of a successful marketing strategy. 'Panorama Nur' video production is the right choice for you if you want to reach your audience in an efficient and interesting way.",
		section1Text4: "Your story deserves to be told in the best possible way, and I am here to help you.",
		sectionTextLine2: "My work",
		section2Title: "My previous work",
		section2Cta: "View all projects",
		section2CtaDesc: "If you want to see how I do it, check out all the projects",
		section3Title: "Video production is the process of creating audiovisual content for various purposes. This includes planning, shooting, editing, and post-production.",
		section3TextLine: "About me",
		section3Desc1: "Through innovation, technology, and strategic thinking, 'Panorama Nur' video production is creative, effective, and targeted.",
		section3Desc2: "I understand your needs and work with you to create video content that sets your brand apart from the competition. With me, you get a partner who will be with you from start to finish to achieve your goals.",
		section3Desc3: "Contact me today and let's work together to create video content that sets you apart.",
		section4Title: "Why Me",
		section4Drone: "'Panorama Nur' video production gives you the opportunity to make your product, service, or workplace look irresistibly attractive. Using drones, I shoot from exceptional aerial angles to create an unforgettable experience.",
		section4Film: "I create educational programs, tutorials, commercials, or short films that set your brand apart from the competition. I can also create a video teaser for your upcoming event or transform your private footage into a unique memory.",
		section44k: "In short, I am an expert who will represent your brand the way it deserves. Contact me today and let's work together to create unforgettable video content.",
		section5Title: "Contact me",
		firstName: "Your name",
		lastName: "Your last name",
		email: "Your email",
		title: "Title",
		message: "Your message",
		sendMessage: "Send message",
	},
	de: {
		projects: "Projekte",
		aboutus: "Über uns",
		whyus: "Warum wir?",
		contactus: "Kontaktiere uns",
		sectionTextLine1: "Eine visuelle Geschichte, die Ihre Ziele erfüllt.",
		ctaContactUs: "Kontaktiere uns",
		section1Text1: "In der heutigen digitalen Welt, wenn die digitale Landschaft in vollem Gange ist, ist Video das mächtigste Werkzeug für die Kommunikation geworden. Die Video-Produktion 'Panorama Nur' ermöglicht es Ihnen, Ihre Geschichte auf beeindruckende Weise zu erzählen.",
		section1Text2: "Wenn Sie nach einer innovativen und effektiven Möglichkeit suchen, Ihr Unternehmen zu präsentieren, ist die Video-Produktion 'Panorama Nur' genau das Richtige für Sie. Mit neuester Technologie und einem kreativen Ansatz erstellen wir hochwertige Videos, die die Aufmerksamkeit Ihres Publikums auf sich ziehen und Ihr Geschäft verbessern werden.",
		section1Text3: "Im Meer der Marketing-Tools ist Video zu einem unverzichtbaren Bestandteil einer erfolgreichen Marketingstrategie geworden. Die Video-Produktion 'Panorama Nur' ist die richtige Wahl für Sie, wenn Sie Ihre Zielgruppe auf effiziente und interessante Weise erreichen möchten.",
		section1Text4: "Ihre Geschichte verdient es, auf die bestmögliche Weise erzählt zu werden, und ich bin hier, um Ihnen zu helfen.",
		sectionTextLine2: "Unsere Arbeit",
		section2Title: "Unsere bisherigen Projekte",
		section2Cta: "Alle Projekte anzeigen",
		section2CtaDesc: "Wenn Sie sehen möchten, wie wir arbeiten, schauen Sie sich alle Projekte an.",
		section3Title: "Video-Produktion ist der Prozess der Erstellung von audiovisuellen Inhalten, die für verschiedene Zwecke verwendet werden. Dazu gehören Planung, Aufnahme, Bearbeitung und Postproduktion.",
		section3TextLine: "Über uns",
		section3Desc1: "Die 'Panorama Nur' Video-Produktion ist kreativ, effektiv und zielgerichtet durch Kombination von Innovation, Technologie und strategischem Denken.",
		section3Desc2: "Ich verstehe Ihre Bedürfnisse und arbeite mit Ihnen zusammen, um Video-Inhalte zu erstellen, die Ihre Marke von der Konkurrenz abheben. Mit mir erhalten Sie einen Partner, der Sie von Anfang bis Ende des Projekts begleitet, um Ihre Ziele zu erreichen.",
		section3Desc3: "Kontaktieren Sie mich noch heute und lassen Sie uns gemeinsam an der Erstellung von Video-Inhalten arbeiten, die Sie hervorheben werden.",
		section4Title: "Warum wir?",
		section4Drone: "Die 'Panorama Nur' Video-Produktion bietet Ihnen die Möglichkeit, Ihr Produkt, Ihre Dienstleistung oder Ihren Arbeitsplatz unwiderstehlich attraktiv erscheinen zu lassen. Mit Hilfe von Drohnen filme ich aus einer außergewöhnlichen Luftperspektive, um ein unvergessliches Erlebnis zu schaffen.",
		section4Film: "Ich erstelle Bildungsprogramme, Tutorials, Musikvideos oder Kurzfilme, die Ihre Marke von der Konkurrenz abheben. Ich können auch einen Video-Trailer für Ihre bevorstehende Veranstaltung erstellen oder Ihre privaten Aufnahmen in eine einzigartige Erinnerung verwandeln.",
		section44k: "Kurz gesagt, ich bin ein Experte, der Ihre Marke so präsentiert, wie sie es verdient. Kontaktieren Sie mich noch heute und lassen Sie uns gemeinsam unvergessliche Video-Inhalte erstellen.",
		section5Title: "Kontaktiere uns",
		firstName: "Ihr Name",
		lastName: "Ihr Nachname",
		email: "Ihre E-Mail",
		title: "Titel",
		message: "Ihre Nachricht",
		sendMessage: "Nachricht senden",
	}
}

export default languages
