
const Card = ({ lang, image, link, title }) => {
	return (
		<a href={link} className="project" target="_blank">
			<img src={image} alt="" className="projectLogo" />

			<div>
				<p>{title}</p>
				<img src="/icons/youtube.svg" alt="" className="youtube" />
			</div>
		</a>
	)
}

export default Card
